
.category-bar-container {
    z-index: 10;
}

.category-dropdown-container{
    
}

.category-navlink{
    color: azure;
}