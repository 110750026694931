

.custom-navbar{
    position: absolute;
    top: 0;
    left: 0;
    background: #480000;
    width: 100%;
}

.top-padding{
    padding-top: 70px;
}

.top-padding-profile{
    padding-top: 90px;
}